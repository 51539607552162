/* ==========================================
   Fonts
   ======================================= */

   @font-face {
    font-family: 'League Spartan';
    src: url('/static/font/leaguespartan-bold.eot');
    src: url('/static/font/leaguespartan-bold.eot?#iefix') format('embedded-opentype'), url('/static/font/leaguespartan-bold.woff2') format('woff2'), url('/static/font/leaguespartan-bold.woff') format('woff'), url('/static/font/leaguespartan-bold.ttf') format('truetype'), url('/static/font/leaguespartan-bold.svg#league_spartanbold') format('svg');
    font-weight: bold;
    font-style: normal;
  }


/* ==========================================
   Vars
   ======================================= */

   $orange: #e26b25;
   $red: #ab314a;
   $pink: #b12d87;
   $soft-blue: #4bbaea;
   $soft-blue-tint:#b1d4e2;
   $moderate-yellow: #b8b833;
   $dark-blue: #2d3895;
   $green: #48ba80;
   $purple: #40256e;
   $yellow: #e3cd00;
   $black: #000000;
   $white: #ffffff;
   $light-grey: #f4f4f4;
   $light-mid-grey: #e6e6e6;
   $grey: #cccccc;
   $mid-grey: #999999;
   $dark-grey: #444444;
   $spartan: 'League Spartan', sans-serif;
   $merri-sans: 'Merriweather Sans', sans-serif;
   $merri: 'Merriweather', serif;
   $large: 36px;
   $medium: 28px;
   $small: 10px;

   $default_background_color: #0831A2;
   $default_title_color: #e3cd00;

   $products_background_color: #40256e;
   $products_title_color: #b8b833;

   $services_background_color: #4bbaea;
   $services_title_color: #2d3895;

   $industries_background_color: #48ba80;
   $industries_title_color: #40256e;

   $support_background_color: #e26b25;
   $support_title_color: #444444;

/* ==========================================
   Foundation Styles
   ======================================= */

  html,body {
      height:100%;
  }
   body {
    font-family: $merri-sans;
    color:$dark-grey;
   }

   .row .row[class*="matrix-grid"] {
    display: block;
    padding: 0;
    margin-right: 0;
    margin-left: 0;

    }

    .nogutter {
      padding-left: 0px;
      padding-right: 0px;
    }

   [class*="matrix-grid"]>li {
      display: block;
      float: left;
      height: auto;
      padding: 5px 5px;
   }
    //Remove padding-left for children 1,4,6....
   [class*="matrix-grid"]>li:nth-child(3n+1) {
     padding-left: 0;
   }
    //Remove padding-right for children 3,6,9...
    [class*="matrix-grid"]>li:nth-child(3n+3) {
      padding-right: 0;
    }

  @media (max-width:1023px) {
   [class*="matrix-grid"]>li:nth-child(3n+1) {
     padding-left: 5px;
   }
    [class*="matrix-grid"]>li:nth-child(3n+3) {
      padding-right: 5px;
    }
  }


   .is-dropdown-submenu-parent.is-down-arrow > a::after, .is-dropdown-submenu-parent.is-right-arrow > a::after {
     content: initial;
   }

   .menu, .submenu {
    z-index: 5;
   }

   .menu {
    width: 100%;

    li {
      display: inline-block;
      float:left;
      a {
        line-height: inherit;
      }
    }

    &.is-dropdown-submenu {
      width: auto;

    }

    a {
      white-space: nowrap;
    }

   }


  .row {
    margin: 0 auto;
    max-width: 1024px;
    width: 100%;
  }

  .hide {
    display: none;
    opacity: 0;
    transition:opacity 1s linear;
  }

  .active {
    display: block;
    transition:opacity 1s linear;
  }

  .slick-slide.slick-active {
    outline: none !important;
  }



  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .top-bar {
  position: relative;
  }

  .top-bar, .top-bar ul.menu {
    background-color: white;
  }

  .top-bar {
    padding: 0px;
    margin: 0px;
  }

  .nav-item {
    padding: 0px;
    margin: 0px;
    position: relative;
  }

  .top-bar ul.submenu {
    margin-left: 0px;
    background-color: $light-grey;
   }

  .menu > li.toplevel > a {
      line-height: 35px;
  }

  .top-bar ul li>a {
      color: #000;
      display: block;
      font-family: "Merriweather Sans";
      font-size: 0.8125rem;
      font-weight: normal;
      /*padding-left: 0.9375rem;*/
      padding: 7px 0.95rem 7px 0.95rem;
      text-transform: uppercase;
      width: 100%;
  }
  /*To overwrite the foundation arrow dropdown*/
  .nav-item.is-dropdown-submenu-parent a {
    padding: 7px 0.95rem 7px 0.95rem;
  }

  /*Styling pagination*/
  .pagination a, .pagination button {
    border: $dark-grey 1px solid;
    display: inline-block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0px;
    color: $dark-grey;
    white-space: nowrap;
    &.active {
      background-color: $green;
      color: $white;
    }
    &:hover {
      background-color: $dark-grey;
      color: $white;
    }
  }


  /* ==========================================
   Submenu Dropdown animation
   ======================================= */


  .top-bar ul li.is-submenu-item>a {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right:25px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .top-bar ul li.is-submenu-item>a:hover {
    transform: scale(1.05) translateX(5px);
  }
  .top-bar ul li.is-submenu-item:hover {
    background-color: $grey;
  }



  /* ==========================================
     Custom Breakpoint
     ======================================= */

  .show-for-md {
    display: none;
  }

  @media only screen and (min-width: 850px) {
    .show-for-md {
      display:block;
    }
  }

  .show-for-sm {
    display: none;
  }

  @media only screen and (max-width: 849px) {
    .show-for-sm {
      display:block;
    }
  }


  .sub-header {
      font-family: $spartan;
      color: $soft-blue;
      text-transform: uppercase;

      &.page-sub-header {
        margin: 30px 0 20px;
        /*padding-left: 0.9375rem;*/
      }

      .matrix-tab & {
        font-size: 1.5em;
        line-height: 245%;
      }
  }

  .color-box {
    padding-left: 0;
    padding-right: 0;
  }

  .matrix-tab {

    cursor: pointer;
    text-align: center;

    &.not-active {
      .sub-header {
        color: $mid-grey;

      }
    }
  }


/* ==========================================
   Common
   ======================================= */


  body {
    -webkit-font-smoothing: auto;
  }

  h1 {
    font-family: $spartan;
    font-size: 2em;
    color: $black;
    margin: 0.67em 0;
  }

  h2 {
    font-family: $spartan;
    font-size: 1.5em;
    color: $black;
    margin: 0;
  }

  h3 {
    font-size: 1.2em;
    color: $black;
  }

  h4 {
    font-size: 1.1em;
    color: $black;
  }

  h5 {
    font-size: 1em;
    color: $black;
  }

  h3, h4, h5, h6 {
    font-family: $merri-sans;
  }

  p, ul, ol, dl {
    font-family: $merri-sans;
    font-size: 0.9em;
    color: $dark-grey;
  }

  a, a:active {
    color: $dark-blue;
  }

  a:hover, a:focus {
    color: $soft-blue;
  }


/* ==========================================
   Header
   ======================================= */

  .viatran-logo {
    position: absolute;
    top: 10px;
    z-index: 101;
    margin-left: 0.9375rem;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .viatran-logo > img {
    max-width: 140px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .nav-contain {
    margin-top: 60px;
    margin-right: 0.9375rem;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .nav-item {
    width: 120px;
  }

  .top-header {
    position: fixed;
    width: 100%;
    background-color:$white;
    z-index: 30;

    & .row {
      border-bottom: 1px solid $dark-grey;
      position: relative;
    }

    &.sticky-nav {

      & .nav-contain {
        margin-top:0px;
      }

      & .viatran-logo {
        top:2px;
        img {
          max-width: 70px;
        }
      }
    }
  }

  .top_page_products, .top_page_services, .top_page_industries, .page_support {
    .top-header {
      & .row {
        border-bottom:none;
      }
      &.sticky-nav .row {
        border-bottom: 1px solid $dark-grey;
      }
    }
  }


  #page-contents {
    padding-top: 109px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  #page-contents.sticky-nav {
    padding-top: 49px;
  }

  .toplevel.nav-item > a {
    text-align: center;
  }

  .toplevel.nav-item.is-active > a, .menu .nav-icon > a.active {
    background: $light-grey;
    position: relative;
    box-shadow: 0px -15px 20px -10px rgba(255,255,255,1);
  }

  .toplevel.nav-item.is-active > a::before, .menu .nav-icon > a.active::before {
    box-shadow: inset 15px 0 15px -15px rgba(200, 200, 200, 1);
    content: " ";
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0;
    width: 15px;
  }

  .toplevel.nav-item.is-active > a::after, .menu .nav-icon > a.active::after {
    box-shadow: inset -15px 0 15px -15px rgba(200, 200, 200, 1);
    content: " ";
    height: 100%;
    right: 0px;
    position: absolute;
    top: 0;
    width: 15px;
    margin: 0;
    padding: 0;
    float: none;
    border:none;
  }

  .menu .nav-icon {
    min-width: 60px;
    /*padding-bottom: 5px;*/
  }

  .menu .nav-icon img {
    max-height: 30px;
    margin: 0;
  }

  .menu .nav-icon > a {
    text-align: center;
    padding: 12px 0.6rem 7px 0.6rem;
  }

  .solid-border {
    box-shadow: 0px 0px 12px 0px rgba(200, 200, 200, 0.75);
  }

  .solid-border::after {
    content: '';
    display: block;
    clear: both;
}

  .subpage-banner {
    margin-bottom: 20px;
    min-height: 300px;
    background-size: cover;
    position: relative;

    &.disable-overlay {
      .color-background {
        display: none;
      }
    }

    .banner-title {
      color: $soft-blue;
      margin-top: 30px;
    }

    .row {
      position: relative;
      z-index: 2;
    }
  }

  .banner a {
    color: #fff;
    border: 1px solid #fff;
    padding: 0.125rem 0.3125rem;
    white-space: nowrap;
  }

  .banner a:hover {
    color: $dark-blue;
    background-color: $white;
  }


  /* ==========================================
   Product Listing
   ======================================= */


  .login-btn {
    border: 1px solid $dark-blue;
    padding: 2px 5px;

    &:hover {
      color: white;
      background-color: $dark-blue;
    }
  }

  .product-features {
    font-family: $merri-sans;
    font-size: 1em;
    line-height: 1.0;
    color: $dark-grey;
    margin-bottom: 10px;
    padding: 0;
  }

  .feature-icon {
    width: 40px;
    height: 40px;
    .list-view & {
      width:30px;
      height: auto;
    }
  }

  .product-features-icon {
    display: inline-block;
    width: 12%;
    vertical-align: middle;
    .list-view & {
      width:20%;
    }
  }

  .product-features-label {
    font-size: 0.9em;
    display: inline-block;
    width: 86%;
    vertical-align: middle;
    .list-view & {
      width:80%;
      font-size: 0.8em;
    }
  }

  .approvals-certs-icon {
    display: inline-block;
    width: 8%;
    margin-right: 1%;
    margin-bottom: 3%;
    vertical-align: middle;
    .list-view & {
      width:10%;
      margin-right: 3%;
      .search-page & {
        width: 13%;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .list-view .approvals {
    margin-top: 1rem;
  }


  .advanced-search-results {
    padding-right:0;
    padding-left:0;
  }

  .no-results {
    margin-left: 15px;
  }

  .list-view h2 {
    font-size: 1.2em;
  }

  .list-view h3 {
    font-size: 1em;
  }

  .list-view p {
    font-size: 0.8em;
  }

  .grid-view h2 {
    font-size: 1em;
    margin-top: 10px;
  }

  .grid-view h3 {
    font-size: 0.9em;
  }

  .grid-view .row .row .column.medium-4 {
    /*min-height: 365px;
    position: relative;*/
    padding-bottom: 60px;
    & .product-cta {
      position:absolute;
      bottom:25px;
    }
  }

  .list-view .row.columns {
      margin-bottom: 15px;
      padding-bottom: 10px;
  }


  .grid-view .row .row {
    position:relative;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }

  .advanced-search-form .search-input-box input {
    font-size: 0.8rem;
  }


  .advanced-search-results a.product-cta, a.btn {
    padding: 0.3125rem 0.9375rem;
    background-color: $dark-blue;
    color: #fff;
    border: 1px solid $dark-blue;
    z-index: 1;

    overflow: hidden;
    position:relative;
    display: inline-block;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
  }

  .advanced-search-results a.product-cta::after, a.btn::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 0;
    top: 50%;
    left: 50%;
    background-color: $white;
    opacity: 0;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .advanced-search-results a.product-cta:hover, a.btn:hover {
    color: $dark-blue;

    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);

  }

  .advanced-search-results a.product-cta:hover::after, a.btn:hover::after {
    height: 100%;
    opacity: 1;
  }

  .advanced-search-results a.product-cta:active::after, a.btn:active::after {
    height: 130%;
    opacity: 1;
  }




/* ==========================================
   Homepage rgba(45,56,149,0.9)
   ======================================= */

.banner {

    position: relative;

    .slider-arrow {

      cursor: pointer;
      color: white;
      position: absolute;
      top: 50%;
      left: 10px;
      z-index: 998;
      font-size: 24px;

      &.slider-next {
        left: auto;
        right: 10px;
      }
    }

    .viatran-slider {
      min-height: 450px;
      margin-bottom: 0;
      .section-theme-support & {
        min-height: inherit;
      }
    }

    .slide.slick-slide {
      min-height: 450px;
      background-size: cover;
      padding-top: 20px;
      padding-bottom: 40px;
      position: relative;
      &.disable-overlay {
        .color-background {
          display: none;
        }
      }
      .section-theme-support & {
        min-height: inherit;
      }
    }



    .slick-dots li button:before {
    opacity: .35;
    color: $white;

    }
    .slick-dots li.slick-active button:before {
        opacity: .75;
        color: $white;
    }



    .slick-next::before {
      content: "→";
    }

    .slick-prev::before {
      content: "←";
    }

    h1, h1 p {
      color: $moderate-yellow;
    }
    h3, h3 p {
      color: $white;
    }

    .row {
      z-index: 2;
      position: relative;
    }
}

  .color-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    opacity: .65;
    z-index: 1;
  }

  .products-we-offer {
    padding: 20px 0 0 0;
    background-color: $white;

    .sub-header {
      text-align: center;
    }
    .header {
      background-color: $dark-grey;
      z-index: 1;
      position: relative;

      margin-bottom: 5px;
      .border-right {
        border-right: 1px solid $white;
      }
      .row {
        border-bottom: 1px solid $dark-grey;
      }
    }

    .category {
      border: 1px solid $dark-grey;
      width: 100%;
    }

    .category-thumbnail {
      text-align: center;
      min-height: 300px;
      overflow: hidden;
      max-height: 300px;
      width: 100%;
      display: block;
    }

    .category-thumbnail > * {
    /*  padding: 1.5rem 0;*/
    }

    .category-thumbnail img {
      width:100%;
      min-width: 100%;
      min-height: 100%;
    }

    .category-title {
      display: table;
      width: 101%; //This fix (hack) is for some cells not cooperating with display:table
      color: $white;
      font-size: 18px;
      font-family: $merri-sans;
      background-color: $dark-grey;
      padding: 0 5% 0 5%;
      height: 56px;
      min-height: 56px;
      position: relative;
      overflow: hidden;
    }

    .category-title span {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      line-height: 20px;
      text-transform: uppercase;
    }

    #tab-product , #tab-industry {
      cursor: pointer;
    }

    .not-active {
      color: #666666;
    }


    .columns {
      padding-left: 0;
      padding-right: 0;
    }

    a {
      color: $black;
    }

  }

  .matrix-tab.active span {
    color: $white;
  }

  .matrix-tab {
    overflow: hidden;
    position:relative;
    display: inline-block;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .matrix-tab:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 101%;
    height: 0;
    top: 50%;
    left: 50%;
    background-color: $white;
    border: 1px solid #444;
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .matrix-tab:hover span, .matrix-tab.not-active:hover span {
    color: $mid-grey;
  }

  .matrix-tab:hover::after {
    height: 100%;
    opacity: 1;
  }

  .matrix-tab:active::after {
    height: 130%;
    opacity: 1;
  }

/*
  .matrix-tab span {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0 14px;
    background: #444444;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .matrix-tab span::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    content: attr(data-hover);
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    transition: background 0.3s;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    -webkit-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }

  .matrix-tab:hover span,
  .matrix-tab:focus span {
    -webkit-transform: rotateX(90deg) translateY(-22px);
    -moz-transform: rotateX(90deg) translateY(-22px);
    transform: rotateX(90deg) translateY(-22px);
  }

  .matrix-tab:hover span::before,
  .matrix-tab:focus span::before {
    background: #666666;
    color:#cccccc;
  }
*/

.mini-searchform {

  input[type=text]{
    /*width: 150px;*/
    display: inline;
    font-size: 0.9em;
  }

  input[type=submit]{
    color: white;
    background-color: $dark-blue;
    border: 0px;
    width: 25px;
  }
}


.advanced-search-form input[type="checkbox"], .advanced-search-form input[type="radio"] {
    height: 25px;
    margin-right: 5px;
    float: left;
}

.form-page input[type="checkbox"], .form-page  input[type="radio"] {
    height: 24px;
    margin-right: 5px;
    float: left;
}

.form-entry-full {
    margin-bottom: 10px;
}

.form-entry.submit-btn {
  clear: both;
}

button, html {
  & input[type="button"], input[type="reset"], input[type="submit"] {
    background-image: none;
    background-color: $dark-blue;
    color: $white;
    border: 1px solid $dark-blue;
    padding: 0.375rem 0.875rem;
    &:hover {
      color: $dark-blue;
      background-color:$white;
    }
  }
}


li.nav-icon {
  .hoverbox {
    width: 275px;
    position: absolute;
    z-index: 1000;
    background-color: $light-grey;
    right: 0px;
    top: 48px;
    padding: 20px;
    display: none;
    font-size: 14px;

    hr {
      margin: 0;
    }

  }

  .advanced-search-hoverbox input#keyword {
    width:82%;
  }

  .advanced-search-hoverbox input#keyword-submit {
    width:18%;
    height: 2.4375rem;
    border: 1px solid $dark-blue;
  }

  .advanced-search-hoverbox input[type="submit"]:hover{
    background-color:$white;
    color:$dark-blue;
  }

  .hoverbox{
    a.btn {
      font-size: 0.9em;
    }

    h5 {
      color:$dark-grey;
    }
    hr {
     margin: 0.5625rem 0;
    }
    p {
      margin-bottom: 0;
    }
    &.contact-hoverbox a.btn {
      margin-top:5px;
    }
  }

  & .hoverbox.hovered {
    display: block;
    &.advanced-search-hoverbox {
      input {
        margin-right: 0px;
      }
      a {
        display: inline-block;
        text-align: left;
        padding-left: 20px;
      }
    }
  }
}

.contact-hoverbox h4 {
  font-family:$spartan;
}



.advanced-search-form {

  label {
    font-size: 0.8em;
    color: $grey;
    &.has-values {
      color: $black;
    }
  }

  padding: 0px 1rem 1rem 1rem;

  background-color: $light-grey;

  .choose-your-options {
    background-color: $dark-blue;
    color: white;
    /*width: 80%;*/
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    text-align: center;
    font-family: $spartan;
    padding-top:5px;

    .options {
      font-size: 2rem;
    }
  }

  input[type=checkbox] {
    margin: 0px;
  }

  .product-group-choices input[type=checkbox] {
    margin-right: 5px;
    height: 24px;
    float: left;
  }

  .product-group-title {
    color: $dark-blue;
    font-weight: bold;
    border-bottom: 1px solid $dark-grey;
    margin-bottom: 5px;
  }

  .product-group-choices label {
    display: block;
    clear: both; 
    line-height: 25px;
  }
}

.advanced-search-form-panel {
    padding: 0;
}

.product-group {
    margin-bottom: 5px;
}

.search-input-box {

  position: relative;
  display: inline-block;
  width: 100%;

  input {
    width: 100%;
  }

  button {
    color: $white;
    background-color: $dark-blue;
    border: 0px;
    width: 39px;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 39px;
    &:hover {
      color: $dark-blue;
      background-color: $white;
      border: 1px solid $dark-blue;
    }
  }

}

.product-search-header {

  padding-top: 2rem;

  h1 {
    color: $green;
    font-family: $spartan;
    margin-top: 0px;
    margin-bottom: 0px;
    text-transform: uppercase;
  }

  .product-display-range {
    font-style: italic;
    margin-top: 0.8125rem;
    color: $dark-grey;
  }

  .product-display-view-toggle {
    margin-top: 0.25rem;
    font-size: 1.5em;
    text-align: right;
    a {
      color : $dark-grey;
    }
    a:hover, a:focus, .active-view  {
      color : $green;
    }
  }
}

.search-page hr {
  border-bottom: 1px solid $dark-grey;
  margin-top: 0.3125rem;
}

.advanced-search-results .row .row, .advanced-search-results .list-view .row {
      border-bottom: solid 1px #444;
}

.pagination {

  .numbered-page {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    display: inline-block;
    width: auto;
  }

  .pagination-pages {
    text-align: center;
  }
  .list-view & {
    &.row {
      border:none;
    }
  }
}

.footer-wrapper {
  font-size: 0.9rem;

  .about-us-section {
    p {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      margin-bottom: 0px;

      a {
        display: block;
        padding: 0;

        &:hover {
          color: $soft-blue;
          /*background-color: $default_background_color;*/
        }
      }
    }
    h3 {
      color:$dark-grey;
    }
  }

  .column {
   /* padding: 0px;*/
  }

  footer.bottom-footer-row {
    padding-top: 15px;
    background-color: $light-grey;
    font-size: 0.8rem;
  }
}

ul.cart {
  display: block;
}

ul.cart li {
  float: none;
  border-bottom: 1px solid #333;
}

iframe {

  &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

}

iframe.advanceware-iframe {
  overflow-y: hidden;
  border: 0px;
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-width: 100%;
  margin-top: 20px !important;
}

@media screen and (max-width: 63.9375em) {
  .reveal {
    margin-top: 0px !important;
    margin-left: 0px !important;
    top: 0px !important;
    left: 0px !important;
    width: 100%;
  }
}

@media screen and (min-width: 64em) {
  .reveal {
    left: 50% !important;
    margin-left: -512px !important;
    width: auto;
    max-width: 1024px;
  }
}


ul.matrix-grid {
  list-style: none;
}

.event-row {
  padding-bottom: 30px;
}

//Themeing gets applied last..
//Note to self: this needs a macro.

.page_support .subpage-banner {
  /*min-height: 600px;*/
}

/*Commenting out the custom coloring per section*/
/*
body.section-theme-products {

  .color-background, .page-sub-header {
    background-color: $products_background_color !important;
  }

  .slide h1, h2.banner-title {
    color: $products_title_color;
  }

}

#tab-products.active, #tab-products:hover, .top-bar-right .menu-item-products.activated-section > a, .top-bar-right .menu-item-products > a:hover {
  background-color: $products_background_color !important;
  color: white !important;
  .sub-header {
    background-color: $products_background_color;
    color: white;
  }
}

.menu-item-products .is-submenu-item.is-dropdown-submenu-item > a:hover {
  background-color: $products_background_color !important;
  color: white !important;
}

body.section-theme-services {
  .color-background, .page-sub-header {
    background-color: $services_background_color !important;
  }

  .slide h1, h2.banner-title {
    color: $services_title_color;
  }
}

#tab-services.active, #tab-services:hover, .top-bar-right .menu-item-services.activated-section > a, .top-bar-right .menu-item-services > a:hover {
  background-color: $services_background_color !important;
  color: white !important;

  .sub-header {
    background-color: $services_background_color;
    color: white;
  }
}

.menu-item-services .is-submenu-item.is-dropdown-submenu-item > a:hover {
  background-color: $services_background_color !important;
  color: white !important;
}


body.section-theme-industries {

  .color-background, .page-sub-header {
    background-color: $industries_background_color !important;
  }

  .slide h1, h2.banner-title {
    color: $industries_title_color;
  }
}

#tab-industries.active, #tab-industries:hover, .top-bar-right .menu-item-industries.activated-section > a, .top-bar-right .menu-item-industries > a:hover {
  background-color: $industries_background_color !important;
  color: white !important;
  .sub-header {
    background-color: $industries_background_color;
    color: white;
  }
}

.menu-item-industries .is-submenu-item.is-dropdown-submenu-item > a:hover {
  background-color: $industries_background_color !important;
  color: white !important;
}

body.section-theme-support {

  .color-background, .page-sub-header {
    background-color: $support_background_color !important;
  }

  .slide h1, h2.banner-title {
    color: $support_title_color;
  }
}

.top-bar-right .menu-item-support.activated-section > a, .top-bar-right .menu-item-support > a:hover {
  background-color: $support_background_color !important;
  color: white !important;
  .sub-header {
    background-color: $support_background_color;
    color: white;
  }
}

.menu-item-support .is-submenu-item.is-dropdown-submenu-item > a:hover {
  background-color: $support_background_color !important;
  color: white !important;
}
*/



//body.section-theme-default {
  .top-bar-section .sub-header {
    background-color: $default_background_color;
  }
  .color-background {
    background-color: $default_background_color !important;
  }

  .slide h1, h2.banner-title {
    color: $default_title_color;
    text-transform: uppercase;
  }

  .color-background {
    opacity: .80;
  }

//}


.resources-page, .tabs-page {
  .resources-tabs, .page-tabs {
    float: left;
    width: 100%;
    h1 {
      margin-top: 0;
    }
    h3.news-title {
      margin-bottom: 0;
    }
    .event-desc, .news-desc {
      margin-top: 1rem;
    }
  }

  .resource-link {
    display: block;
    font-size: 0.8rem;
    line-height: 1.5rem;
    & i {
      padding-right:0.3125rem;
    }
  }
  @media only screen and (min-width: 40.063em) { 
    .resources-right, .push-right {
      border-left: 1px solid $dark-grey;
      min-height: 350px;
    }
  }
  @media only screen and (max-width: 40.063em) { 
    .resources-right, .push-right {
      margin-top: 2rem;
    }
  }

  .tabs, .tabs-content {
    border: none;
  }

  .tabs-title > a {
    font-size: 0.9rem;
    padding: 0.85rem 0.3125rem 0.3125rem 0;
    background: none;
    border-bottom: 1px solid $white;
    color: $dark-blue;
    &:hover {
      color: $soft-blue;
    }
  }

  .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
    border-bottom: 1px solid #444;    
    color: $black;
  }
  #glossary-tabs {
    margin-bottom: 1.5rem;
  }
  #glossary-tabs .tabs-title {
    a {
      padding: 0.5rem;
      border: 1px solid $white;
    }    
    a:focus, a[aria-selected='true'] {
      border: 1px solid $dark-grey;
    }
  }

  .tabs-panel {
    padding: 0;
  }

  .tabs-content.vertical {
    border: none;
  }

  .glossary-entry {
    padding-bottom: 1rem;
  }

  .glossary-entry span {
    display: block;
    font-size: 0.9rem;
    &.glossary-def {
      font-size: 0.7rem;
      color: $mid-grey;
    }
  }

  .glossary-header {
    font-weight: bold;
  }
  .accordion {
    border:none;
  }
  .accordion-title {
    color: $dark-blue;    
    font-size: 1rem;
    padding-left: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      color: $soft-blue;
      font-size: 1.035rem;
      background:none;
    }
  }
  .accordion-item.is-active .accordion-title {
    color: $black;
    border-bottom: 1px solid $dark-grey;
    padding-bottom: 0.5625rem;
  }
  .accordion-content {
    border:none;
    padding-left: 0;
    padding-top: 0.5625rem;
  }
}




.forms-page {

  .form-entry {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .qty-column {
    width: 20%;
    float: left;
    padding-right: 10px;
  }

  .pt-column {
    width: 40%;
    float: left;
    padding-right: 10px;
  }

  .serial-column {
    width: 40%;
    float: left;
  }

  .add-more-pt {
    color: $dark-blue;
    cursor: pointer;
    &:hover {
      color: $soft-blue;
    }
  }

  .column-left {
    border-right: #ccc solid 1px;
  }

  .invalid {
    background-color: $light-grey;
    padding: 5px;
  }

  .invalid-text {
    color: red;
    font-size: 0.9rem;
  }

  .form-header {
    font-size: 1.2rem;
    border-bottom: 1px solid #444;
    margin-bottom: 1.5rem;
  }

  .form-label {
    font-size: 0.9rem;
  }

}

.hidden-nav {
  display: none;
}

.slick-dots li button:before {
  font-size: 20px;
}

.locations-page {
  & h3 {
    margin-top: 1rem;
  }
  & select {
    height: 2.4375rem;
    padding: 0.5rem;
    border: 1px solid $mid-grey;
    margin: 0 0 1rem;
    font-size: 0.9rem;
    line-height: normal;
    color: $dark-grey;
    background-color: $light-grey;
  }
  & form .column {
    white-space:nowrap;
  }
  .row .column.small-12 {
    margin-bottom: 35px;
  }
  .row .small-up-12.medium-up-4 {
    margin-bottom: 55px;
  }
}

.locations-page input {
  height: 2.4375rem;
  padding: 0.5rem;
  border: 1px solid $dark-blue;
  margin: 0 0 1rem;
  font-size: 0.9rem;
  line-height: normal;
  &.btn {
    background-color: $dark-blue;
    color: $white;
    min-width: 25%;
    &:hover {
      background-color: $white;
      color: $dark-blue;
    }
  }
}


/* Approvals Page */

.approvals-page {
  margin-top: 25px; 
}

.approvals-page .approval {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.approvals-page .prod-image {
  text-align: center;
}


.approvals-page .approval img {    
    max-width: 100%;
}

.approvals-page .approval ul {
    list-style: none;
    margin: 0 0 0 0.875rem;
    padding: 0;
}

.approvals-page .prod-desc {
    padding-left: 1.25rem;
    border-left: 1px solid #444;
}

.approvals-page .prod-desc h1 {
  margin-top: 0;
}

.approvals-page .prod-desc h2 {
  
}


.nonprintable-version {
  display: block;
}

.printable-version {
  display: none;
}

.red {
    color: red;
}